const header = $('#header');
const donationForm = $('#donation-single-form');
const mobileHeaderHeight = 80;
const tabletHeaderHeight = 128;
const deskHeaderHeight = 224;
const mobileWidth = 420;
const tabletWidth = 1200;

var oldScrollPos = 0;
$(window).on('scroll resize', function() {
    
    var headerHeight = header.outerHeight();
    var currentScrollPos = $(window).scrollTop();

    // calculate header height for home page fixed header
    if ($(window).width() < mobileWidth) {
        var fixedHeaderHeight = mobileHeaderHeight;
    } else if ($(window).width() < tabletWidth) {
        var fixedHeaderHeight = tabletHeaderHeight;
    } else {
        var fixedHeaderHeight = deskHeaderHeight;
    }

    // if top
    if (currentScrollPos == 0) {
        header.removeClass('header__show header__hide');
        //console.log('we are at the top');
    }
    // if scroll down
    else if (currentScrollPos > oldScrollPos) {
        // if scroll down more than header height
        if (currentScrollPos > headerHeight) {
            header.addClass('header__hide').removeClass('header__show');
        }
        //console.log($(window).scrollTop());
    }
    // if scroll up
    else {
        // if scroll up less than header height
        if (currentScrollPos <= (headerHeight - fixedHeaderHeight) ) {
            header.removeClass('header__show header__hide');
        } else {
            if (donationForm.length) {
                // on donation form don't show/hide header 
            } else {
                header.addClass('header__show').removeClass('header__hide');
            }
        }
    }
    oldScrollPos = currentScrollPos;
});