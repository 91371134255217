//=require third-party/owl.carousel.js
//=require third-party/lib.js
//=require third-party/floatHeader.js

$( document ).ready(function() {

    initTiers();

    var mobileNavOpen = false;
    var lockingBody = false;
    var scrollPosition = $(document).scrollTop();

    if (!lockingBody) {
        $(window).scroll(function () {
            scrollPosition = $(document).scrollTop();
        });
    }

    /******************************************************************************************
    MENU
    ******************************************************************************************/
    $('.nav-item').click(function (e) {
        e.preventDefault();
        var navID   = $(this).attr('data');
        var elm     = $(this);
        var target  = $('#' + navID + '-menu');
        if (target.hasClass('sub-menu-active')) {
            target.slideUp();
            target.removeClass('sub-menu-active');
            $('.nav-item').removeClass('nav-item-active');
        }
        else {
            if ($('.sub-menu-active').length) {
                $('.sub-menu-active').slideUp();
                setTimeout( function() {
                    $('.sub-menu').removeClass('sub-menu-active');
                    $('.nav-item').removeClass('nav-item-active');
                    target.slideDown();
                    target.addClass('sub-menu-active');
                    if (mobileNavOpen) {
                        target.css('top', 'unset')
                    }
                    else {
                        target.css('top', 96)
                    }
                    elm.addClass('nav-item-active');
                }, 300);
            }
            else {
                target.slideDown();
                target.addClass('sub-menu-active');

                if (mobileNavOpen) {
                    target.css('top', 'unset')
                }
                else {
                    target.css('top', 96)
                }
                elm.addClass('nav-item-active');
            }
        }
    });

    // if ($(window).width() >= 1200) {
    //     $('.hamburger').addClass('is-active');
    //     $('.bottom-header').addClass('active');
    //     // $('.bottom-header').css('opacity', '1');
    //     // $('.bottom-header').css('left', '0');
    //     // $('.bottom-header, body>main, body>footer').removeClass('visually-hidden');
    // } else {
    //     // $('.bottom-header').css('opacity', '0');
    //     // $('.bottom-header').css('left', '100%');
    // }
    $('.hamburger').removeClass('is-active');
    $('.bottom-header').removeClass('active');
    $('.sub-menu').removeClass('sub-menu-active').slideUp();
    $('.nav-item').removeClass('nav-item-active');
    unlockBody();

    $(window).resize(function () {
        $('.hamburger').removeClass('is-active');
        $('.bottom-header').removeClass('active');
        $('.sub-menu').removeClass('sub-menu-active').slideUp();
        $('.nav-item').removeClass('nav-item-active');
        mobileNavOpen = false;
        unlockBody();
        // if ($(window).width() >= 1200) {
        //     $('.hamburger').addClass('is-active');
        //     $('.bottom-header').addClass('active');
        //     // $('.bottom-header').css('opacity', '');
        //     // $('.bottom-header').css('left', '');
        //     // $('.bottom-header, body>main, body>footer').removeClass('visually-hidden');
        // } else {
        // }
    });

    /******************************************************************************************
    MENU MOBILE
    ******************************************************************************************/

    $('.hamburger').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-active');
        if ($(this).hasClass('is-active')) {
            $('.bottom-header').addClass('active');
            // $('.bottom-header').css('opacity', '1');
            // $('.bottom-header').css('left', '0%');
            //$('body>main, body>footer').addClass('visually-hidden');
            mobileNavOpen = true;
            lockBody();
        }

        else {
            $('.bottom-header').removeClass('active');
            // $('body>main, body>footer').removeClass('visually-hidden');
            // $('.bottom-header').animate({
            //     opacity: 0
            //   }, 300);
            // $('.bottom-header').css('left', '100%');
            mobileNavOpen = false;
            unlockBody();
        }
    });

    function lockBody() {
        var body = $('body'),
            bodyScrollPos = $(window).scrollTop();
              //bodyWidthBeforeNavOpen = $('body').width();
        body.css({
            'position': 'fixed',
            'top': -bodyScrollPos + 'px',
            'left': '0',
            'right': '0'
        });
        // keep the header fixed position
        header.css({
            'position': 'fixed',
            'top': 0,
        });
        /*
        $("#navigation").scrollTop(0);
        // if there was a scrollbar on page, add some padding of same width to body so whole page doesn't shift
        var scrollbarWidth = $('body').width() - bodyWidthBeforeNavOpen;*/
    }
    function unlockBody() {
        var body = $('body'),
            bodyScrollPos = -parseInt(body.css('top'));
        // if body isn't already locked, don't do anything
        if (body.css('position') != 'fixed') return;
        // reset the styles we added to body, header and nav panels when we locked the page
        body.css({
            'position': '',
            'top': '',
            'left': '',
            'right': '',
        });
        // reset the header fixed position
        setTimeout(function(){
            header.css({
            'position': '',
            'top': '',
            });
        }, 500);
        // window position as it was
        $(window).scrollTop(bodyScrollPos);
    }

    /******************************************************************************************
    HERO DONATION
    ******************************************************************************************/

    // Hero Donation

    var donationType    = "single";    // donationType
    var selectedTier    = true;
    //var optionContent;
    //var placeholderContent;

    if ($('.hero-donation-block').length) {
        donationType = "single";
    }

    $('.option').click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass('option-selected')) {
            $('.option').removeClass('option-selected');
            $(this).addClass('option-selected');
        }

        var donationType = $(this).attr('data-type');
        console.log(donationType);

        //optionContent       = $('.option-content').html();
        //placeholderContent  = $('.placeholder').html();
        //$('.option-content').html(placeholderContent);
        //$('.placeholder').html(optionContent);

        if (donationType == 'monthly') {
          $('.option-content.monthly').show();
          $('.option-content.single').hide();
          $('.amount-description__mobile--monthly').show();
          $('.amount-description__mobile--single').hide();
        } else if (donationType == 'single') {
          $('.option-content.monthly').hide();
          $('.option-content.single').show();
          $('.amount-description__mobile--monthly').hide();
          $('.amount-description__mobile--single').show();
        }

        initTiers();
    });

    function initTiers() {
        $('.tier').click(function (e) {
            e.preventDefault();

            if (!$(this).hasClass('tier-selected')) {
                $(this).siblings('.tier').removeClass('tier-selected');
                $(this).addClass('tier-selected');

                var dataAmount = $(this).find(".amount").attr("data-amount");
                var frequency = $(this).hasClass("tierMonthly") ? "monthly" : "single";

                $('.amount-description__mobile--' + frequency + ' p').removeClass('selected');
                $('.amount-description__mobile--' + frequency + ' p[data-amount="' + dataAmount + '"]').addClass('selected');
            }

            selectedTier = true;
        });
    }

    // $('#other-amount').on('focus', function (e) {
    //     // e.preventDefault();
    //
    //     $('.tier').removeClass('tier-selected');
    //     selectedTier = false;
    // });

    $('.hero-donation .donate-btn').click(function (event) {
        event.preventDefault();

        var donation_amount = 0;
        var otherAmount = $('#other-amount').val();
        var donationType = $('.option-selected').attr('data-type');
        var formTitle = $('#form-title').val();
        var formHandle = $('#form-handle').val();
        var utmSource = $('#utm-source').val();
        var utmMedium = $('#utm-medium').val();
        var utmCampaign = $('#utm-campaign').val();
        var utmId = $('#utm-id').val();
        var customReturnUrl = $('#return-url').val();

        otherAmount = otherAmount.replace(/[^\d\.]/g,'');
        otherAmount = Number(otherAmount).toFixed(2);

        if (isValidAmount(otherAmount)) {
            donation_amount = otherAmount;
        } else {
            if (donationType == 'single') {
              $('.tierSingle').each(function (index, element) {
                if (element.classList.contains('tier-selected')) {
                  donation_amount = element.querySelector('.amount').getAttribute("data-amount");
                }
              });
            } else if (donationType == 'monthly') {
              $('.tierMonthly').each(function (index, element) {
                if (element.classList.contains('tier-selected')) {
                  donation_amount = element.querySelector('.amount').getAttribute("data-amount");
                }
              });
            }
        }
        // if monthly donation, it goes to CAF page
        if (donation_amount > 0){
            if (donationType == 'single') {
                var redirectUri = '/donation-' + donationType + '?amount=' + donation_amount;

                if(utmSource) {
                    redirectUri += '&utm-source=' + utmSource;
                }

                if(utmMedium) {
                    redirectUri += '&utm-medium=' + utmMedium;
                }

                if(utmCampaign) {
                    redirectUri += '&utm-campaign=' + utmCampaign;
                }

                if(utmId) {
                    redirectUri += '&utm-id=' + utmId;
                }

                if (formTitle) {
                    redirectUri += '&form-title=' + formTitle;
                }

                if (formHandle) {
                    redirectUri += '&form-handle=' + formHandle;
                }

                if (customReturnUrl) {
                    redirectUri += '&return-url=' + customReturnUrl;
                }

                window.location = redirectUri;
            } else if (donationType == 'monthly') {
                window.location = 'https://cafdonate.cafonline.org/753';
            }
        }

        return false;
    });

    function isValidAmount(val) {
        var n = Number(val);
        return isFinite(n) && !isNaN(n) && n > 0;
    }

    /******************************************************************************************
    DONATION FORM
    ******************************************************************************************/

    // Make UK the first option in Countries dropdown
    var options = $('#form-input-country').find($('option'));
    if (options.length) {
        $(options[237]).insertAfter($(options[0]));
    }

    // Update the donation amount if it's changed in the input field at the top of the page
    /* Moved to donation form template
    $('#donationAmountInput').on('change', function(event){
        var amount = $(this).val();
        amount = amount.replace(/[^\d\.]/g,'');
        amount = Number(amount).toFixed(2);
        amount = !isNaN(amount) ? amount : 0;

        $(this).val(amount);
        $('[name=donationAmount]').val(amount);

        // Also update Gift aid
        $('.gift-info .amount').text(new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount));
        $('.gift-info .amount_plus_gift_aid').text(new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount * 1.25));
    });*/

    /******************************************************************************************
    MISC
    ******************************************************************************************/

    checkDoubleLine();

    function checkDoubleLine() {
        var services    = $('.services');
        var mobile      = $('.services-mobile');
        var infoIcons   = $('.info-icons');


        if (services.next().next().hasClass('info-icons')) {
            services.css('border-bottom', 'none');
            mobile.find('div.services-border').last().css('display', 'none');
        }

        else if (infoIcons.next().hasClass('services')) {
            services.css('border-top', 'none');
            mobile.find('div.services-border').first().css('display', 'none');
        }
    }

    /******************************************************************************************
    COOKIE POPUP
    ******************************************************************************************/

    function showCookieBanner() {
        $("#cookieBanner").addClass("cookie-banner--open");
        $("#cookieBanner").removeAttr("aria-hidden");
    }

    function closeCookieBanner() {
        $("#cookieBanner").removeClass("cookie-banner--open");
        $("#cookieBanner").attr("aria-hidden", "true");
    }

    if (!localStorage.getItem("cookiePerformance") || !localStorage.getItem("cookieMarketing")) {
        showCookieBanner();
    }

    // 'Accept all cookies'
    $("#cookieButtonAccept").on("click", function() {
        localStorage.setItem("cookiePerformance", "allow");
        localStorage.setItem("cookieMarketing", "allow");
        closeCookieBanner();
    });

    // 'Manage cookies'
    $("#cookieButtonManage").on("click", function() {
        $("#cookieBannerPageOne").hide();
        $("#cookieBannerPageTwo").show();
    });

    // 'Save and close'
    $("#cookieButtonSet").on("click", function() {
        localStorage.setItem("cookiePerformance", $("input[name='cookiePerformance']:checked").val() || "deny");
        localStorage.setItem("cookieMarketing", $("input[name='cookieMarketing']:checked").val() || "deny");
        closeCookieBanner();
    });


    /******************************************************************************************
    FILTER
    ******************************************************************************************/

    // $('.filter-checkbox').click(function (e) {

    //     var elm = $(this);

    //     if (elm.prop('checked')) {
    //         elm.parent().addClass('filter-label-checked');
    //     } else {
    //         elm.parent().removeClass('filter-label-checked');
    //     }
    // });

    $('.js-ajax-entries-btn').click(function(e) {
        e.preventDefault();
        // console.log('Ajax');
        var btn = $(this),
            target = btn.data('target'),
            url = btn.attr('href'),
            currentScrollPos = $(window).scrollTop();
        btn.addClass('disabled');
        $.ajax({
            url: url,
            cache: false
        })
        .done(function (html) {
            btn.removeClass('disabled');
            var content = $(html).find(target).children(),
                newButton = $(html).find('.js-ajax-entries-btn');
            // keep the y position
            $('body').css({
                top: -currentScrollPos,
            });
            // add more content
            $(target).append(content);
            // if there's a new button in the ajaxed content, copy the url of it to our current button so it's ready to load in next lot
            if (newButton.length > 0) {
                btn.attr('href', newButton.attr('href'));
            } else {
                // otherwise remove button
                // btn.remove();
                btn.parents('.more-entries').remove();
            }
        });
    });

});


/******************************************************************************************
OWL CAROUSELS
*******************************************************************************************/

// Set up custom Owl carousel navigation
var owlNavSetup = function ($owlId) {
    if ($owlId.find('.owl-nav').length) {
        $('<div class="nav-prev"></div>').insertBefore($owlId.find('.owl-dots'));
        $('<div class="nav-next"></div>').insertAfter($owlId.find('.owl-dots'));

        $owlId.find('.nav-prev, .owl-dots, .nav-next').addClass('nav-wrap-inner');

        $owlId.find('.nav-wrap-inner').wrapAll('<div class="nav-wrap"></div>');
    }

    $owlId.find('.nav-next').click(function (e) {
        e.preventDefault();
        $owlId.find('.owl-next').trigger('click');
    });

    $owlId.find('.nav-prev').click(function (e) {
        e.preventDefault();
        $owlId.find('.owl-prev').trigger('click');
    });
}

// Instantiate each Owl carousel
$('.owl-carousel').each(function () {

    // Assign the element a unique ID and store it for instantiating this carousel
    if (!$(this).attr('id')) {
        var uniqueId = 'owl-' + Date.now().toString();
        $(this).attr('id', uniqueId);
    }
    var owlId  = '#' + $(this).attr('id');
    var $owlId = $(owlId);

	// Instatiate this carousel using the element's unique ID (#owl-n) stored above
    //
    // onRefresh, onRefreshed stuff below creates equal height owl items
    // (based on https://github.com/OwlCarousel2/OwlCarousel2/issues/378).
    //
    // Set child items to 100% height to get equal heights!
	var owl = $owlId,
		owlOptions = {
			dots: true,
			nav: false,
			loop: true,
			items: 1,
			responsive: {
				700: {
					items: 2
				},
				1100: {
					items: 3
				}
			},
			onTranslated: function () {
				checkAnimations();
			}
		},
		disableFrom = owl.get(0).hasAttribute('data-disable-owl-from') ? owl.attr('data-disable-owl-from') : null;

	// if we're showing this owl at all sizes, it's simple!
	if (!disableFrom) {
		owl.owlCarousel(owlOptions);
        owlNavSetup($owlId);
		return;
	}

	// otherwise we need to enable/disable it at the breakpoint specified on our html tag
	// based on http://stackoverflow.com/questions/28251644/disabling-owl-carousel-at-a-specific-viewport-width
	if ($(window).width() < disableFrom) {
		owl.owlCarousel(owlOptions);
	} else {
		owl.addClass('off');
	}
	$(window).resize(function () {
		if ($(window).width() < disableFrom) {
			if (owl.hasClass('off')) {
				owl.owlCarousel(owlOptions);
				owl.removeClass('off');
			}
		} else {
			if (!owl.hasClass('off')) {
				owl.addClass('off').trigger('destroy.owl.carousel');
				owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
			}
		}
	});
    owlNavSetup($owlId);
});

function isIE() {
    var ua = window.navigator.userAgent;
    var ieBrowser = false;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {// IE 10 or older => return version number
        ieBrowser = true;
    }
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {// IE 11 => return version number
        ieBrowser = true;
    }
    return ieBrowser;
}
if (isIE()) {
  var ieBanner = $('#ie-banner');
  ieBanner.css({
    'display': 'block',
  })
}
